<script>
import Layout from "../../layouts/main.vue";

import axios from 'axios';

export default {
  data() {
    return {
      data: {
        id: '',
      }
    };
  },
  props: {
  },
  components: {
    Layout,
  },
  mounted() {
    this.loadingEpisode();
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          if(response.data.episode.permissions.isFounder || response.data.episode.permissions.isAuthor || response.data.episode.permissions.isGuest) {
            this.$router.push('/live/' + this.$route.params.id + '/' + this.$route.params.session);
          }
          else {
            this.data = response.data;
          }
        })
        .catch(function () {
        });
    },
    acceptInvite() {
      const hash = this.$route.query.h;
      axios.post('/api/live/invite/' + this.$route.params.id + '/' + this.$route.params.session + '/?h=' + hash, null, { withCredentials: true })
        .then((response) => {
          if(response.data.redirect) {
            this.$router.push(response.data.redirect);
          }
        })
        .catch(function () {
        });
    },
    rejectInvite() {
      this.$router.push('/');
    }
  },
  computed: {
    getEpisodeId: function() {
      if(this.data && this.data.episode && this.data.episode.id)
        return this.data.episode.id;
      return null;
    },
    getEpisodeMemo() {
      if(this.data && this.data.episode && this.data.episode.title)
        return this.data.episode.title;
      return '';
    }
  }
};
</script>

<template>
  <Layout>

    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4 text-center">

            <div class="avatar-lg mx-auto mt-2">
              <router-link :to="{ name: 'episode', params: { id: getEpisodeId } }" v-if="getEpisodeId">
                <div class="avatar-title rounded-circle text-center" v-if="!data.episode.urlAvatar">{{ data.episode.title[0] }}</div>
                <img v-bind:src="data.episode.urlAvatar" alt="" class="img-fluid d-block rounded-circle" v-if="data.episode.urlAvatar" />
              </router-link>
            </div>

            <div class="mt-4 pt-2" v-if="getEpisodeId">
              <h4>{{ data.episode.title }}</h4>
              <p class="text-muted mx-4">{{ data.episode.memo }}</p>
              <div class="mt-4">
                <button type="button" class="btn btn-primary" @click="acceptInvite">Accept</button>
                <button type="button" class="btn btn-danger ms-2" @click="rejectInvite">Reject</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
